<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.statistic.product.title') }}
        </h2>
        <button :data-kt-indicator="refreshLoading ? 'on' : null" class="btn btn-primary er fs-6 px-8 py-4" type="button" @click="refreshStatistic" :disabled="refreshLoading">
            <span v-if="!refreshLoading" class="indicator-label">{{ $t('pages.statistic.product.refresh') }}</span>
            <span v-if="refreshLoading" class="indicator-progress">
                {{ $t("messages.wait") }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
        </button>
    </div>
    <StatisticProduct ref="productStatistic"></StatisticProduct>
</template>

<script>
import StatisticProduct from "@/components/statistic/product";
export default {
    name: "index",
    components: {
      StatisticProduct
    },
    data(){
        return {
            refreshLoading: false
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t('pages.statistic.product.title'), [this.$t('menu.statistic.title')]);
    },
    methods: {
        refreshStatistic(){
            this.refreshLoading = true;

            this.axios.post(this.endpoints['statistic_product']).then(response => {
                this.onResponse(response.data, () => {
                    this.$refs.productStatistic.loadStatistics();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.refreshLoading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>