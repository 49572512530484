<template>
    <div class="separator separator-dashed mt-3 mb-7 border-gray-400"></div>
    <div class="row g-5 g-xl-8 mb-5 mb-xl-0" v-if="statistic.sale_report">
        <div class="d-flex justify-content-end mb-5">
            <el-tooltip :content="$t('pages.statistic.product.saleReport.exportTooltip')" popper-class="max-w-300px" placement="bottom">
                <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportExcel('sale_report')">
                    <span v-if="!exportLoading" class="indicator-label">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                        </span>
                        {{ $t("common.export") }}
                    </span>

                    <span v-if="exportLoading" class="indicator-progress">
                        <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        {{ $t("messages.wait") }}
                    </span>
                </button>
            </el-tooltip>
        </div>
        <custom-table
            :runModeServer="false"
            :title="$t('pages.statistic.product.saleReport.title')"
            :subTitle="$t('pages.statistic.product.saleReport.subTitle')"
            :items="statistic.sale_report"
            :columns="saleReport.fields"
            :pagination="saleReport.pagination"
            filterComponentName="StatisticProductFilter">
            <template v-slot:product="{ row: record }">
                <router-link :to="'/ecommerce/product-management/product?id=' + record.product_id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                    <span class="fw-bolder">{{ record.product_title }}</span>
                </router-link>
            </template>
            <template v-slot:last_ordered_date="{ row: record }">
                {{ $moment(record.last_ordered_date).format("DD.MM.YYYY - HH:mm:ss") }}
            </template>
        </custom-table>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table/index.vue";

export default {
    name: "index",
    components: {CustomTable},
    data(){
        return {
            saleReport: {
                fields: [
                    {
                        name: this.$t("pages.statistic.product.saleReport.cols.productID"),
                        key: "product_id",
                        class: ""
                    },
                    {
                        name: this.$t("pages.statistic.product.saleReport.cols.product"),
                        scopedSlots: {customRender: "product"},
                    },
                    {
                        name: this.$t("pages.statistic.product.saleReport.cols.saleCount"),
                        key: "sale_count"
                    },
                    {
                        name: this.$t("pages.statistic.product.saleReport.cols.lastOrderedDate"),
                        scopedSlots: {customRender: "last_ordered_date"},
                    },
                ],
                pagination: {}
            },
            exportLoading: false,
        }
    },
    computed:{
        statistic(){
            return this.$store.state.statistic.product.table.data;
        }
    },
    mounted(){
        this.loadStatistics();
    },
    methods: {
        loadStatistics(){
            this.$store.dispatch('statistic/product/get', {}).then(() => {
                this.saleReport.pagination = {
                    current: 1,
                    pageSize: 50,
                    total: this.statistic.sale_report ? this.statistic.sale_report : 0,
                }
            });
        },
        exportExcel(statisticKey) {
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['statistic_product_export'],
                method: 'post',
                responseType: "blob",
                data: {
                    statistic_key: statisticKey
                }
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>